<template>
  <div
    class="code-box pointer flex-all-center font-size-16"
    @click="codeBtnClick()"
  >
    {{ timerCode }}
  </div>
  <!-- 111 -->
</template>


<script>
export default {
  name: "codeButton",
  data() {
    return {
      //是否处于倒计时状态
      counting: false,
      timerCode: "发送验证码",
      timerCount: 0,
      interval: null,
    };
  },
  watch:{
    '$store.state.isStopCountDown'(isStop){
      if(isStop){
        this.clearIntervalFun();
      }
    }
  },
  methods: {
    //开始倒计时并传递按钮已点击回调
    codeBtnClick() {
      if (!this.counting) {
        this.$emit("sendCodeFun", this.countDownAction);
      }
    },
    countDownAction() {
      this.timerCount = 60;
      this.counting = true;
      this.timerCode = `重新获取(${this.timerCount}s)`;
      this.interval = setInterval(() => {
        this.timerCount -= 1;
        if (this.timerCount === 0) {
          this.interval && clearInterval(this.interval);
          (this.timerCode = "获取验证码"), (this.counting = false);
        } else {
          this.timerCode = `重新获取(${this.timerCount}s)`;
        }
      }, 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.interval);
      });
    },
    clearIntervalFun() {
      this.interval && clearInterval(this.interval);
      this.timerCount = 0;
      this.timerCode = "获取验证码";
      this.counting = false;
    },
    beforeRouteLeave(to, from, next) {
      this.$destroy();
      next();
    },
  },
};
</script>

<style lang="scss" scoped>
.code-box {
  width: 128px;
  height: 54px;
  background: #f1f4f6;
  border-radius: 6px;
  color: #3468fe;
}
.code-box:hover {
  background: #d7d7d7;
}
.code-box-account-text {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  line-height: 22px;
  color: #080b19;
}
</style>