<template>
	<div class="invite-unlogin-content flexcolumn-row-center">
		<div class="avatar-wrapper flex-all-center">
		    <img v-if="invitationData.avatar" class="avatar-img" :src="invitationData.avatar" alt />
		    <span v-else>{{invitationData.real_name.substr(0,1)}}</span>
		</div>
		<span class="font-size-16 invitation-text">{{invitationData.real_name}}</span>
		<span class="font-size-20 project-name-text">{{invitationData.project_name}}</span>
		<div class="content-wrapper flexcolumn-row-center">
			<div class="input-wrapper flexrow-column-center" style="margin-top: 2.25rem;">
				<span class="iconfont iconzhanghao account-img"></span>
				<input
					class="input font-size-16"
					type="text"
					v-model.trim="userForm.realName"
					placeholder-class="project-input-pl font-size-16"
					placeholder="请填写2-20位真实姓名"
				/>
			</div>
			<div class="input-wrapper flexrow-column-center" style="margin-top: 1rem;">
				<span class="iconfont iconshoujihao account-img"></span>
				<input
					class="input font-size-16"
					type="text"
					v-model.trim="userForm.phone"
					placeholder-class="project-input-pl font-size-16"
					placeholder="请输入手机号"
				/>
			</div>
			<div class="input-wrapper flex-row-space-between" style="margin-top: 1rem;border:0;">
				<div class="input-wrapper flexrow-column-center" style="width:18.8125rem">
					<span class="iconfont iconyanzhengma account-img"></span>
					<input
						class="input font-size-16"
						type="text"
						v-model.trim="userForm.code"
						placeholder-class="project-input-pl font-size-16"
						placeholder="请输入验证码"
					/>
				</div>
				<CodeButton class="code-btn font-size-16" @sendCodeFun="sendCodeFun" />
			</div>
			<div class="join-wrapper flex-all-center font-size-18 pointer" @click="onClickJoin()">立即加入</div>
		</div>
	</div>
</template>

<script>
	import CodeButton from "@components/CodeButton.vue"
	import { isPhone, checkCode } from '@utils/login.js'
	export default {
		props:{
			invitationData:Object
		},
		data() {
			return {
				userForm:{realName:'',phone:'',code:''}
			}
		},
		methods: {
			sendCodeFun(callback) {
				let isTrue = isPhone(this.userForm.phone);
				if (isTrue) {
					let params = {
						phone: this.userForm.phone,
						email: ''
					};
					this.$emit('sendCodeFun', params);
					callback();
				}
			},
			onClickJoin(){
				if(this.userForm.realName === ''){
					this.$message.warning('姓名不能为空');
					return;
				}
				if(this.userForm.realName.length < 2 || this.userForm.realName.length > 20){
					this.$message.warning('姓名长度错误！');
					return;
				}
				if(isPhone(this.userForm.phone) && checkCode(this.userForm.code)){
					this.$emit('onClickJoin', this.userForm);
				}
			}
		},
		components:{
			CodeButton
		}
	}
</script>

<style lang="scss" scoped>
.invite-unlogin-content {
	position: relative;
	width: 100%;
	.avatar-wrapper{
	    width:71px;
	    height:71px;
	    border-radius: 50%;
	    background-color: #3567fe;
		border: 3px solid #ffffff;
	    .avatar-img{
	        width:68px;
	        height:68px;
	        border-radius: 50%;
	    }
	}
	.invitation-text{
		color: #B3B9C7;
		margin-top: 13px;
    }
    .project-name-text{
        margin-top:26px;
        color: white;
    }
	.content-wrapper{
		margin-top: 26px;
		width:486px;
		height:auto;
		background: #20222B;
		box-shadow: 0 4px 20px 0 rgba(12, 12, 12, 0.5);
		border-radius: 18px;
		.input-wrapper {
			width: 436px;
			height: 54px;
			border-radius: 6px;
			border: 1px solid rgba(255, 255, 255, 0.1);
			.project-input-pl{
				color: #868C97;
			}
			.account-img {
				font-size: 24px;
				color: #868c97;
				margin: 0 10px 0 10px;
			}
			.input {
				width: 85%;
				height: 98%;
				border: 0;
                outline: none;
                background: transparent;
                color: white;
			}
			.code-btn {
				width: 123px;
				height: 54px;
				background: #2A2C36;
                border-radius: 6px;
                color: white;
			}
		}
		.join-wrapper{
			width: 436px;
			height: 54px;
			background: #3468FE;
			border-radius: 6px;
            margin-top: 73px;
            color: white;
            margin-bottom: 73px;
		}
	}
}
</style>