
/**
 * 验证手机号码
 * @param {string} phoneNum 
 */
export function isPhoneTure(phoneNum){
    var myreg = /^1[3456789]\d{9}$/;
    return myreg.test(phoneNum);
}
/**
 * 验证邮箱
 * @param {string} email 
 */
export function isEmailTure(email){
    var myreg = /^[A-Za-z0-9]+([_\.A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z\.]{2,6}$/;// eslint-disable-line
    return myreg.test(email)
}
/**
 * 验证是否全数字
 * @param {string} number 
 */
export function isAllNumber(number){
    var reg = /^[0-9]*$/;
    return reg.test(number);
}