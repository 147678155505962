<template>
  <div class="invite-complete-content flexcolumn-row-center">
    <div class="content-wrapper flexcolumn-row-center">
      <div class="title-wrapper flexrow-column-center">
        <span class="iconfont icongou gou-img"></span>
        <span class="font-size-26" style="color:#ffffff">加入成功！</span>
      </div>
      <span class="font-size-18 content-text" v-html="contextValue"></span>
      <div
        class="join-wrapper flex-all-center font-size-18 pointer"
        @click="onClickToProject()"
      >
        进入工作台
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invitationData: Object,
  },
  data() {
    return {
      contextValue: "",
    };
  },
  created() {
    this.contextValue = `您已加入<span style="color:#ffffff;font-weight:bold">${this.invitationData.project_name}</span>，赶快进入工作台参与协助吧！`;
  },
  methods: {
    onClickToProject() {
      this.$emit("onClickToProject");
    },
  },
};
</script>

<style lang="scss" scoped>
.invite-complete-content {
  position: relative;
  width: 100%;
  .content-wrapper {
    width: 486px;
    height: auto;
    background: #20222b;
    box-shadow: 0 4px 20px 0 rgba(12, 12, 12, 0.5);
    border-radius: 18px;
    .title-wrapper {
      margin-top: 58px;
      margin-bottom: 61px;
      .gou-img {
        color: #19be6b;
        margin-right: 20px;
        font-size: 30px;
      }
    }
    .content-text {
      color: #b3b9c7;
      width: 324px;
      text-align: center;
      line-height: 25px;
    }
    .join-wrapper {
      width: 436px;
      height: 54px;
      background: #3468fe;
      border-radius: 6px;
      margin-top: 90px;
      margin-bottom: 73px;
      color: white;
    }
  }
}
</style>
