import {isPhoneTure,isEmailTure,isAllNumber} from './dataVerify'
import vue from 'vue'

/**
 * 验证传入的参数是否为正确的手机号或邮箱
 * @param {string} str 手机或邮箱号
 * @return {string} 'phone' 或 'email'，空值为错误
 */
export function isPhoneOrEmail(str){
    if(str === ''){
        vue.prototype.$message.warning('手机号或邮箱不能为空!');
        return '';
    }
    let isPhone = isPhoneTure(str);
    let isEmail = isEmailTure(str);
    if(!isPhone && !isEmail){
        vue.prototype.$message.warning('手机号或邮箱格式错误!');
        return '';
    }
    return isPhone ? 'phone' : 'email';
}
/**
 * 验证手机号正确性
 * @param {string} str 手机号
 * @return {boolean}
 */
export function isPhone(str){
    if(str === ''){
        vue.prototype.$message.warning('手机号不能为空!');
        return false;
    }
    if(!isPhoneTure(str)){
        vue.prototype.$message.warning('手机号格式错误!');
        return false;
    }
    return true;
}
/**
 * 验证邮箱正确性
 * @param {string} str 邮箱
 * @return {boolean}
 */
export function isEmail(str){
    if(str === ''){
        vue.prototype.$message.warning('邮箱不能为空!');
        return false;
    }
    if(!isEmailTure(str)){
        vue.prototype.$message.warning('邮箱格式错误!');
        return false;
    }
    return true;
}
/**
 * 验证数据的完整性，是否可以继续登录流程
 * @param {object} userForm 用户输入信息
 * @param {number} type 当前类型（0.验证码 1.密码）
 * @return {string} 'phone' 或 'email'，空值为错误
 */
export function isCanLogin(userForm,type){
    if(userForm.username === ''){
        vue.prototype.$message.warning('手机号或邮箱不能为空!');
        return '';
    }
    if(userForm.password === ''){
        vue.prototype.$message.warning(type === 0 ? '验证码不能为空!' : '密码不能为空!');
        return '';
    }
    if(type === 0 && (userForm.password.length !== 6 || !isAllNumber(userForm.password))){
        vue.prototype.$message.warning('验证码格式错误!');
        return '';
    }
    if(type === 1 && (userForm.password.length < 6 || userForm.password.length > 18)){
        vue.prototype.$message.warning('密码格式错误!');
        return '';
    }
    let isPhone = isPhoneTure(userForm.username);
    let isEmail = isEmailTure(userForm.username);
    //验证2个输入框内容是否都合法
    if(!isPhone && !isEmail){
        vue.prototype.$message.warning('手机号或邮箱格式错误!');
        return '';
    }
    return isPhone ? 'phone' : 'email';
}
/**
 * 验证数据的完整性，是否可以修改密码
 * @param {object} userForm 
 * @return {string} 'phone' 或 'email'，空值为错误
 */
export function isCanResetPw(userForm){
    if(userForm.username === ''){
        vue.prototype.$message.warning('手机号或邮箱不能为空!');
        return '';
    }
    let isPhone = isPhoneTure(userForm.username);
    let isEmail = isEmailTure(userForm.username);
    if(!isPhone && !isEmail){
        vue.prototype.$message.warning('手机号或邮箱格式错误!');
        return '';
    }
    if(!checkTwoPw(userForm)){
        return '';
    }
    return isPhone ? 'phone' : 'email';
}
/**
 * 验证数据的完整性，是否可以继续注册流程
 * @param {object} userForm 用户输入信息
 * @param {number} type 当前类型（0.手机注册 1.邮箱注册）
 * @return {boolean}
 */
export function isCanRegister(userForm,type){
    if(type === 0 && !isPhone(userForm.phone)){
        return false;
    }
    if(type === 1 && !isEmail(userForm.email)){
        return false;
    }
    if(!checkTwoPw(userForm)){
        return false;
    }
    return true;
}

/**
 * 验证验证码格式正确性
 * @param {string} code 
 */
export function checkCode(code){
    if(code === ''){
        vue.prototype.$message('验证码不能为空!');
        return false;
    }
    if(code.length !== 6 || !isAllNumber(code)){
        vue.prototype.$message('验证码格式错误!');
        return false;
    }
    return true;
}
/**
 * 判断是否是字母和数字的组合
 * @param {string} str 
 */
export function checkRate(str){
    var zg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
    return zg.test(str);
}


/**
 * 验证验证码和2次密码输入正确性
 * @param {object} userForm 
 * @return {boolean}
 */
function checkTwoPw(userForm){
    if(userForm.code === ''){
        vue.prototype.$message.warning('验证码不能为空!');
        return false;
    }
    if(userForm.code.length !== 6 || !isAllNumber(userForm.code)){
        vue.prototype.$message.warning('验证码格式错误!');
        return false;
    }
    if(userForm.password === ''){
        vue.prototype.$message.warning('密码不能为空!');
        return false;
    }
    if(userForm.confirm_pw === ''){
        vue.prototype.$message.warning('请确认密码!');
        return false;
    }
    if(userForm.password.length < 6 || userForm.password.length > 18 || !checkRate(userForm.password)){
        vue.prototype.$message.warning('密码格式错误!');
        return false;
    }
    if(userForm.password !== userForm.confirm_pw){
        vue.prototype.$message.warning('两次密码不一致!');
        return false;
    }
    return true;
}