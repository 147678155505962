<template>
  <div class="invite-content flex-all-center">
    <img class="logo-img" :src="companyBasicInfo.light_logo" alt />
    <template v-if="Object.keys(invitationData).length > 0">
      <InviteComplete
        v-if="isComplete"
        :invitationData="invitationData"
        @onClickToProject="onClickToProject"
      />
      <InviteForLogin
        v-else-if="isLogin"
        :invitationData="invitationData"
        @onClickJoin="onClickJoin"
      />
      <InviteForUnLogin
        v-else
        :invitationData="invitationData"
        @sendCodeFun="sendCodeFun"
        @onClickJoin="onClickJoin"
      />
    </template>
    <div v-else class="flexcolumn-row-center">
      <span class="iconfont iconduankai_duankai share-img"></span>
      <span class="no-share-text font-size-24">{{ inviteInfo }}</span>
    </div>
  </div>
</template>

<script>
import request from "@request/api/index.js";
import InviteForLogin from "./components/InviteForLogin.vue";
import InviteForUnLogin from "./components/InviteForUnLogin.vue";
import InviteComplete from "./components/InviteComplete.vue";
export default {
  props:{
    code:String,
    isProject:Boolean
  },
  data() {
    return {
      invitationData: {},
      projectItems: {},
      project_id: 0,
      isComplete: false,
      inviteInfo: "",
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.user.token !== "";
    },
		companyBasicInfo(){
			return this.$store.state.user.companyBasicInfo;
		}
  },
  created() {
    let params = { code: this.code };
    this.isProject
      ? this.requestInviteInfo(params)
      : this.requestShootInviteInfo(params);
  },
  methods: {
    async requestInviteInfo(params) {
      try {
        let res = await request.invite.getInviteInfo(params);
        this.invitationData = res.data.data;
      } catch (err) {
        if (err.data.code === 3055) {
          this.inviteInfo = "该邀请链接已失效";
        } else if (err.data.code === 3056) {
          this.inviteInfo = "该邀请链接不存在";
        } else {
          this.inviteInfo = "该邀请链接已被删除";
        }
      }
    },
    async requestShootInviteInfo(params) {
      try {
        let res = await request.invite.requestShootLinkInfo(params);
        this.invitationData = res.data.data;
      } catch (err) {
        if (err.data.code === 3055) {
          this.inviteInfo = "该邀请链接已失效";
        } else if (err.data.code === 3056) {
          this.inviteInfo = "该邀请链接不存在";
        } else {
          this.inviteInfo = "该邀请链接已被删除";
        }
      }
    },
    async joinProject(params) {
      try {
        let res = await request.invite.getJoinProgect(params);
        this.project_id = res.data.data.project_id;
        this.isComplete = true;
        if (this.$store.state.user.userInfo.identity == 6) {
            await  request.account.requestAccountInfo();
        }
      }catch(err){
        console.log(err);
      }
    },
    async joinShoot(params) {
      try {
        let res = await request.invite.requestShootProjectJoin(params);
        this.project_id = res.data.data.project_id;
        this.isComplete = true;
        if (this.$store.state.user.userInfo.identity == 6) {
            await  request.account.requestAccountInfo();
        }
      } catch (err) {
        console.log(err);
      }
    },
    sendCodeFun(params) {
      request.login.requestCode(params);
    },
    onClickJoin(data) {
      const params = {code:this.code};
      if(data){
        params.phone = data.phone;
        params.sms_code = data.code;
        params.name = data.realName;
      }
      this.isProject ? this.joinProject(params) : this.joinShoot(params);
    },
    onClickToProject() {
      if(this.isProject){
        window.location.href = "/v20/#/project/" + this.project_id;
      }else{
        window.location.href = "/v20/#/shoot/" + this.project_id;
      }
    },
  },
  components: {
    InviteForLogin,
    InviteForUnLogin,
    InviteComplete,
  },
};
</script>

<style lang="scss" scoped>
.invite-content {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background: url("~@/assets/imgs/invitationBg.png") center center no-repeat;
  background-size: cover;
  .logo-img {
    position: absolute;
    top: 52px;
    left: 64px;
    max-width: 150px;
    object-fit: cover;
  }
  .share-img {
    font-size: 90px;
    color: #323b55;
  }
  .no-share-text {
    margin-top: 41px;
    color: white;
  }
}
</style>