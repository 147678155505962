<template>
	<div class="invite-login-content flexcolumn-row-center">
		<div class="content-wrapper flexcolumn-row-center">
			<div class="avatar-wrapper flex-all-center">
				<img v-if="invitationData.avatar" class="avatar-img" :src="invitationData.avatar" alt />
				<span v-else>{{invitationData.real_name.substr(0,1)}}</span>
			</div>
			<span class="font-size-16 invitation-text">{{invitationData.real_name}}</span>
			<span class="font-size-20 project-name-text">{{invitationData.project_name}}</span>
			<div class="join-wrapper flex-all-center font-size-18 pointer" @click="onClickJoin()">立即加入</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			invitationData:Object
		},
		methods: {
			onClickJoin(){
				this.$emit('onClickJoin', null);
			}
		}
	}
</script>

<style lang="scss" scoped>
.invite-login-content {
	position: relative;
	width: 100%;
	.content-wrapper{
		width:486px;
		height:auto;
		background: #20222B;
		box-shadow: 0 4px 20px 0 rgba(12, 12, 12, 0.5);
		border-radius: 18px;
		.avatar-wrapper{
			margin-top: 62px;
		    width:71px;
			height:71px;
			border-radius: 50%;
			background-color: #3567fe;
			border: 3px solid #ffffff;
			.avatar-img{
				width:68px;
				height:68px;
				border-radius: 50%;
			}
		}
		.invitation-text{
			color: #B3B9C7;
			margin-top: 13px;
		}
		.project-name-text{
			margin-top:26px;
			color: white;
		}
		.join-wrapper{
			width: 436px;
			height: 54px;
			background: #3468FE;
			border-radius: 6px;
			margin-top: 78px;
			margin-bottom: 73px;
			color: white;
		}
	}
}
</style>